<template>
  <form @submit.prevent="onSubmit()">
    <div class="view view--health--add">
      <div class="view-header" v-if="pageTitle">
        <div class="view-header__footer">
          <BtnBack />
          <h1 class="page-title">
            {{ pageTitle }}
          </h1>
        </div>
      </div>

      <div class="view-body">
        <Section>
          <Container>
            <h2 class="section-title">Désignation</h2>
            <div class="form grid">
              <div class="tmp-col">
                <Input id="nom" label="Nom générique" />
                <Input v-if="this.helperService.userHasProfil('super-administrateur')" id="euid" label="EUID" />
              </div>
              <div class="tmp-col">
                <SelectExtended id="cultures" label="Cultures" optionKey="uid" optionValue="nom" apiEndpoint="culture"
                :apiParams="{ limit: 0, sort: 'nom.ASC' }"
                  multiple chips />
              </div>
              <!-- Noms commerciaux -->
              <div class="col--fullwidth">
                <RepeatableInputs v-if="repeatables.noms_commerciaux && repeatables.noms_commerciaux.default"
                  label="Noms commerciaux" id="noms_commerciaux"
                  :default-selection="repeatables.noms_commerciaux.default" :errors="errors"
                  :items="repeatables.noms_commerciaux.items" />
                <RepeatableInputs v-else-if="repeatables.noms_commerciaux" label="Noms commerciaux"
                  id="noms_commerciaux" :errors="errors" :items="repeatables.noms_commerciaux.items" />
              </div>
              <!-- Fin Noms commerciaux -->

              <div class="tmp-col">
                <Input id="code_cooperative" label="Code Coop" />
              </div>
              <div class="tmp-col">
                <Input id="code_obtenteur" label="Code obtenteur" />
              </div>
              <div class="tmp-col">
                <Input id="code_ctps" label="Code ctps" />
              </div>
              <div class="tmp-col">
                <SelectExtended id="obtenteur_uid" label="Obtenteur" optionKey="uid" optionValue="nom"
                  apiEndpoint="fournisseur" />
              </div>
            </div>

          </Container>
        </Section>
        <Section class="bg-gray-lightestest section--border-top">
          <Container>

            <h2 class="section-title">Fiche d'identité</h2>

            <div class="form grid">
              <div class="tmp-col">
                <Input type="number" id="annee_inscription" label="Année d'inscription" />
              </div>
              <div class="tmp-col">
                <Input type="textarea" id="informations_complementaires" label="Informations complémentaires" />
              </div>
              <!-- Typ_var -->
              <div class="tmp-col">
                <Input id="typ_var1" label="TYP_VAR1" />
              </div>
              <div class="tmp-col">
                <Input id="typ_var2" label="TYP_VAR2" />
              </div>
              <div class="tmp-col">
                <Input id="typ_var3" label="TYP_VAR3" />
              </div>
              <div class="tmp-col">
                <Input id="typ_var4" label="TYP_VAR4" />
              </div>
              <div class="tmp-col">
                <Input id="typ_var5" label="TYP_VAR5" />
              </div>
              <div class="tmp-col">
                <Input id="code_gnis" label="Code GNIS" />
              </div>
              <div class="tmp-col">
                <SelectExtended id="sous_thematiques" label="Sous-thèmes" optionKey="uid" optionValue="nom"
                  groupLabel="designation" groupValues="sous_thematiques" apiEndpoint="dictionnaire/thematique"
                  :groupSelect="true" multiple />
              </div>
              <div class="tmp-col">
                <Input id="serie" label="Série" />
              </div>
              <div class="tmp-col">
                <SelectExtended id="unite_uid" label="Unité" option-key="uid" option-value="valeur"
                  apiEndpoint="unite" />
              </div>

              <div class="tmp-col">
                <Radio id="actif" required :items="[
                  { label: 'Oui', value: true },
                  { label: 'Non', value: false },
                ]" label="Statut actif" inputStyle="inline" @change="changeActif" />
              </div>
              <div class="col--fullwidth">
                <!-- Composants -->
                <RepeatableInputs v-if="repeatables.composants && repeatables.composants.default" label="Composition"
                  id="composants" :default-selection="repeatables.composants.default" :errors="errors"
                  :items="repeatables.composants.items" />
                <RepeatableInputs v-else-if="repeatables.composants" label="Composition" id="composants"
                  :errors="errors" :items="repeatables.composants.items" />
                <!-- Fin Composants -->
              </div>
            </div>

          </Container>
        </Section>
        <Section class="section--border-top">
          <Container>

            <h2 class="section-title">Pictogrammes</h2>

            <SelectExtended id="pictogrammes" label="Pictogrammes" option-key="media" option-value="nom"
              apiEndpoint="pictogramme" chipsImages multiple />

          </Container>
        </Section>
        <Section class="bg-gray-lightestest section--border-top">
          <Container>

            <h2 class="section-title">Remarques</h2>

            <Input type="textarea" id="remarques" label="Remarques" />

          </Container>
        </Section>
      </div>

      <div class="view-footer">
        <div class="view-actions view-actions--left">
          <Btn text="Annuler" :to="{ name: 'seeds' }" />
          <Btn v-if="method === 'put'" v-show="helperService.userHasPermission('produit_remove') && method === 'put'"
            hollow @click="modalDeleteProduct = true" text="Supprimer" />
          <Btn v-show="helperService.userHasPermission('produit_add')
            || helperService.userHasPermission('produit_edit')" btnType="submit"
            :text="method === 'post' ? 'Enregistrer' : 'Modifier'" color="primary" />
        </div>
      </div>
    </div>

    <!-- Modals -->
    <Modal v-if="$route.params.id && datas.product" title="Supprimer le produit&nbsp;?" :active="modalDeleteProduct"
      :data="datas.product" @modal-close="modalDeleteProduct = false">
      <template v-slot:modal-body="{ data }">
        <p>
          Voulez vous vraiment supprimer le produit
          <b>{{ data.nom }}</b>?
        </p>
      </template>
      <template v-slot:modal-footer>
        <Btn text="Annuler" @click="modalDeleteProduct = false" />
        <Btn text="Supprimer" @click="deleteProduct()" icon="trash-alt" color="primary" />
      </template>
    </Modal>
  </form>
</template>

<script>
import Input from '@/components/form/Input.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Btn from '@/components/base/Btn.vue'
import SelectExtended from '@/components/form/SelectExtended.vue'
import Radio from '@/components/form/Radio.vue'
import RepeatableInputs from '@/components/form/RepeatableInputs.vue'
import Modal from '@/components/layout/Modal.vue'
import BtnBack from '@/components/base/BtnBack.vue'

export default {
  name: 'SeedAddEditView',

  components: {
    BtnBack,
    Modal,
    Btn,
    Input,
    Section,
    Container,
    SelectExtended,
    Radio,
    RepeatableInputs,
  },

  props: {
    pageTitle: {
      type: String,
    },
    typeUid: {
      type: String,
      default: 'SEMENCE',
    },
  },

  data() {
    const validationSchema = this.yup.object().shape({
      // Commun
      actif: this.yup.boolean().required(),
      euid: this.yup.string().nullable(),
      nom: this.yup.string().nullable(),
      remarques: this.yup.string().nullable(),
      code_cooperative: this.yup.string().nullable(),
      type_uid: this.yup.string(),
      famille_uid: this.yup.object().nullable(),
      unite_uid: this.yup.object().nullable(),
      pictogrammes: this.yup.array().nullable(),
      cultures: this.yup.object().nullable(),
      sous_thematiques: this.yup.array().nullable(),
      composants: this.yup.array().nullable(),
      noms_commerciaux: this.yup.array().of(
        this.yup.object().shape({
          fournisseur_uid: this.yup.object().nullable(),
          nom: this.yup.string().nullable(),
          code_interne: this.yup.string().when(
            ['fournisseur_uid', 'nom'],
            // eslint-disable-next-line max-len
            (fournisseur, nom, schema) => (fournisseur && nom === '' ? schema.required() : schema.nullable()),
          ),
        }),
      ).nullable(),

      // Semence
      code_obtenteur: this.yup.string().nullable(),
      code_ctps: this.yup.string().nullable(),
      annee_inscription: this.yup.string().test('len', "L'année doit avoir exactement 4 caractères",
        (val) => {
          if (typeof (val) !== 'undefined' && val && val.length > 0) {
            return val.length === 4
          }
          return true
        }).nullable(),
      informations_complementaires: this.yup.string().nullable(),
      typ_var1: this.yup.string().nullable(),
      typ_var2: this.yup.string().nullable(),
      typ_var3: this.yup.string().nullable(),
      typ_var4: this.yup.string().nullable(),
      typ_var5: this.yup.string().nullable(),
      serie: this.yup.string().nullable(),
      obtenteur_uid: this.yup.object().nullable(),
      code_gnis: this.yup.string().nullable(),
    })

    const { isSubmitting, errors } = this.formService.initFrom(
      validationSchema,
    )
    const onSubmit = this.formService.handleSubmit((values) => {
      if (values.noms_commerciaux && values.noms_commerciaux.length > 0) {
        let counter = 0
        values.noms_commerciaux.forEach((element) => {
          if (
            (
              element.code_interne !== null || element.nom !== null
              || element.code_interne !== '' || element.nom !== ''
            )
            && !element.fournisseur_uid
          ) {
            this.formService.setFieldError('noms_commerciaux', 'Le champ fournisseur est obligatoire')
            this.emitter.emit('alert', { type: 'error', content: 'Le champ fournisseur est obligatoire.' })
            counter += 1
          }
        })
        if (counter === 0) {
          this.handleItem(values)
        }
      } else {
        this.handleItem(values)
      }
    })

    return {
      id: null,
      form: {
        entites: [],
        thematique: {},
        references: [],
        pictos: [],
        actif: true,
      },
      modalDeleteProduct: false,
      repeatables: {},
      endpoint: 'produit',
      method: 'post',
      datas: {},
      params: {},
      schema: {},
      submitErrors: false,
      onSubmit,
      errors,
      isSubmitting,
    }
  },

  watch: {
    errors() {
      this.formService.handleErrors(this.isSubmitting)
    },
  },

  async created() {
    this.form.entites = await this.productService.getEntities(true)
    this.id = this.$route.params.id
    this.form.references = await this.productService.getReferenceByUids('famillechimique', 'cible')
    this.form.thematique = await this.productService.getThematique(this.typeUid)
    this.form.pictos = await this.productService.getPictos()
    this.repeatables = this.productService.getRepeatables(this.form)
    if (this.id) {
      this.endpoint = `produit/${this.id}`
      this.method = 'put'
      this.getProduct()
    } else {
      this.schema = this.formService.populateShema({ actif: true })

      this.formService.setFormValues(this.schema)
    }
  },

  unmounted() {
    this.form = {}
    this.repeatables = {}
  },

  methods: {
    changeActif() {
      this.schema.actif = !this.schema.actif
    },
    deleteProduct() {
      this.productService.delete(this.datas.product.id, 'seeds')

      this.modalDeleteProduct = false
    },
    getProduct() {
      this.fetchService.get(`${this.endpoint}`).then((response) => {
        this.datas.product = response.data

        if (this.datas.product.entite.id !== this.$store.state.auth.user.data.entite.id) {
          this.$router.push({ name: 'forbidden' })
        }

        this.schema = this.formService.populateShema(this.datas.product)
        this.form.actif = this.schema.actif

        this.repeatables = this.productService
          .setDatas(this.datas)
          .setRepeatables(this.repeatables)
          .getRepeatablesFromProduct('composants', 'noms_commerciaux')

        this.schema.famille_uid = {
          key: this.datas.product.famille?.uid,
          value: this.datas.product.famille?.nom,
        }

        this.schema.obtenteur_uid = {
          key: this.datas.product.obtenteur?.uid,
          value: this.datas.product.obtenteur?.nom,
        }

        this.schema.unite_uid = {
          key: this.datas.product.unite?.uid,
          value: this.datas.product.unite?.valeur,
        }

        this.schema.cultures = this.datas.product.cultures?.map((culture) => ({
          key: culture?.uid,
          value: culture?.nom,
        }))

        this.schema.sous_thematiques = this.datas.product.sous_thematiques.map((thematique) => ({
          key: thematique?.uid,
          value: thematique?.nom,
        }))

        this.schema.pictogrammes = this.datas.product.pictogrammes.map((picto) => ({
          key: picto.media?.id,
          uid: picto?.uid,
          value: picto?.nom,
        }))

        this.formService.setFormValues(this.schema)
      })
    },

    async handleItem(values) {
      // Traitement des données saisies et complément
      const postParams = values

      if (postParams.annee_inscription === '') {
        delete postParams.annee_inscription
      }

      Object.keys(postParams).forEach((param) => {
        if (typeof postParams[param] === 'object') {
          if (param === 'pictogrammes') {
            if (postParams[param] !== null) {
              postParams[param] = this.productService.sanitizeParams(postParams[param], true)
            }
          } else if (postParams[param] !== null) {
            postParams[param] = this.productService.sanitizeParams(postParams[param])
          }
        }
        if (postParams[param] === '') {
          postParams[param] = null
        }
      })

      postParams.type_uid = this.$props.typeUid
      postParams.entite_id = this.form.entites.entite.id

      if (!postParams.pictogrammes) {
        postParams.pictogrammes = []
      }

      if (postParams.sous_thematiques && postParams.sous_thematiques.length) {
        postParams.sous_thematiques = postParams.sous_thematiques.map(
          (thematique) => ({ uid: thematique.key || thematique.uid }),
        )
      }

      if (postParams.cultures && postParams.cultures.length) {
        postParams.cultures = postParams.cultures.map(
          (culture) => ({ uid: culture.key }),
        )
      }

      this.fetchService[this.method](this.endpoint, postParams).then(
        (response) => {
          const { id } = response.data

          // MAJ composition
          // TODO voir ce que dit Louis à propos du ticket créé sur
          // la mise à jour des compos quand on les supprime toutes
          if (postParams.composants) {
            postParams.composants = postParams.composants.filter(
              (composant) => composant.uid !== null
                && composant.quantite !== null
                && composant.unite_uid !== null,
            )

            this.fetchService.put(
              `produit/${id}/composition`,
              { composants: postParams.composants || [] },
            ).then(
              (resolve) => {
                this.datas.composants = resolve.data
                console.log('Composition mise à jour')
              },
              (reject) => {
                this.formService.handleApiError(reject)
              },
            )
          }

          // Fin MAJ Composition

          // Traitement des noms_commerciaux
          if (postParams.noms_commerciaux && postParams.noms_commerciaux.length > 0) {
            const { namesCreated, idsNamesDeleted, namesUpdated } = this.productService
              .getNamesToStore(
                postParams,
                this.repeatables.noms_commerciaux.default || [],
              )

            namesCreated.forEach((name) => {
              // eslint-disable-next-line no-param-reassign
              name.produit_id = id

              if (name.nom === '' || name.nom === null) {
                // eslint-disable-next-line no-param-reassign
                name.nom = null
              }

              if (name.code_interne || name.fournisseur_uid || name.nom) {
                this.fetchService.post('nom_commercial', name).then(
                  () => {
                    console.log('Nom commercial créé')
                  },
                  (cne) => {
                    this.formService.handleApiError(cne)
                  },
                )
              }
            })

            idsNamesDeleted.forEach((nid) => {
              this.fetchService.delete(`nom_commercial/${nid}`).then(
                () => {
                  console.log(`Nom commercial id : ${nid} supprimé`)
                },
                (dne) => {
                  this.formService.handleApiError(dne)
                },
              )
            })

            namesUpdated.forEach((name) => {
              // eslint-disable-next-line no-param-reassign
              name.produit_id = id

              if (name.nom === '' || name.nom === null) {
                // eslint-disable-next-line no-param-reassign
                name.nom = null
              }

              this.fetchService.put(`nom_commercial/${name.id}`, name).then(
                () => {
                  console.log(`Nom commercial id : ${name.id} modifié`)
                },
                (une) => {
                  this.formService.handleApiError(une)
                },
              )
            })
          }
          // Fin noms_commerciaux
          this.emitter.emit('alert', {
            type: 'success',
            content: this.method === 'post' ? 'Le produit a bien été créé.' : 'Le produit a bien été modifié.',
          })

          this.$router.push({ name: 'seeds' })
        },
      ).catch((e) => this.formService.handleApiError(e))
    },
  },
}
</script>
